import * as React from 'react'
import { graphql, Link } from 'gatsby'
import { SliceZone } from '@prismicio/react'
import { withPrismicPreview } from 'gatsby-plugin-prismic-previews'

import { components } from '../slices'
import { Layout } from '../components/Layout'

export const query = graphql`
  query BlogPostQuery($id: String) {
    prismicPost(id: { eq: $id }) {
      _previewable
      id
      uid
      lang
      type
      url
      data {
        date
        title {
          text
        }
        body {
          ... on PrismicSliceType {
            id
            slice_label
            slice_type
          }
          ...PostDataBodyText
          ...PostDataBodyQuote
          ...PostDataBodyImageWithCaption
          ...PostDataBodyCode
        }
      }
    }
  }
`

const Post = ({ data }) => {
  if (!data) return null

  const post = data.prismicPost.data
  const title = post.title.text || 'Untitled'

  const date_options = { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric' };
  const date = new Date(post.date)
  const date_text = date.toLocaleDateString("en-US", date_options)

  return (
    <Layout>
      <div className="container post-header">
        <div className="back">
          <Link to="/posts">back to posts</Link>
        </div>
        <h1>{title}</h1>
        <p className="blog-post-meta">
          <time>{date_text}</time>
        </p>
      </div>
      <SliceZone slices={post.body} components={components} />
    </Layout>
  )
}
export default withPrismicPreview(Post)
