import * as React from 'react'

import prismicLogo from '../images/logo-prismic.svg'

export const Footer = () => (
  <footer className="container">
    <p>
    </p>
  </footer>
)
