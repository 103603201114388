import * as React from 'react'
import { graphql } from 'gatsby'
import Markdown from "react-markdown"
import { PrismicRichText } from '@prismicio/react'
import * as prismicH from '@prismicio/helpers'
import { useEffect } from 'react'
import Prism from 'prismjs'

import "prismjs/components/prism-markup-templating.js"
import "prismjs/components/prism-ruby.js"
import "prismjs/components/prism-bash.js"
import "prismjs/components/prism-erb.js"

export const CodeBlock = ({ slice }) => {
  useEffect(() => {
    // call the highlightAll() function to style code blocks
    Prism.highlightAll()
  })

  const rawMarkdown = prismicH.asText(slice.primary.code_block.richText)
  console.log('originalMarkdown', slice.primary.code_block.richText)
  console.log('rawMarkdown', rawMarkdown)
  return (
    <div className="post-text container">
      <div>
        <Markdown children={rawMarkdown} />
      </div>
    </div>
  )
}

export const query = graphql`
  fragment PostDataBodyCode on PrismicPostDataBodyCode {
    primary {
      code_block {
        richText
      }
    }
  }
`
