import * as React from 'react'
import { graphql, Link } from 'gatsby'
import { withPrismicPreview } from 'gatsby-plugin-prismic-previews'

import { Layout } from '../components/Layout'
import { BlogPosts } from '../components/BlogPosts'

export const query = graphql`
  query MyQuery($limit: Int!, $skip: Int!) {
    allPrismicPost(
      sort: { fields: data___date, order: DESC }
      limit: $limit
      skip: $skip
    ) {
      nodes {
        _previewable
        id
        url
        data {
          title {
            text
          }
          date(formatString: "MMM D, YYYY")
          tags {
            tag
          }
          body {
            ... on PrismicPostDataBodyText {
              id
              slice_label
              slice_type
              primary {
                text {
                  richText
                }
              }
            }
          }
        }
      }
      pageInfo {
        currentPage
        pageCount
      }
    }
  }
`

const Homepage = ({ data }) => {
  if (!data) return null

  const docs = data.allPrismicPost

  return (
    <Layout>
      <div className="container post-header">
        <div className="back">
          <Link to="/">back home</Link>
        </div>
      </div>
      <BlogPosts docs={docs} />
    </Layout>
  )
}

export default withPrismicPreview(Homepage)
